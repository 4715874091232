import '../../../css/App.css';
import axios from "axios";
import React, {useEffect, useState} from "react";
import Spinner from 'react-bootstrap/Spinner';
import {
    addSubcategory,
    createSubcategory,
    deleteSubcategory,
    getAllCategories,
    getOneCategory
} from "../../../api/information";
import CardGroup from "../../utils/CardGroup";
import DropdownGroup from "../../utils/DropdownGroup";
import PersonInformationHandleDropdown from "./PersonInformationHandleDropdown";
import ProfileInCol from "../ProfileInCol";
import FileInputGroup from "../../utils/FileInputGroup";
import {useStoreContext} from "../../hooks/useStoreContext";
import {STORE_ACTIONS} from "../../../StoreProvider";

function PersonSettings( ) {
    const {store, dispatch} = useStoreContext();
    const _ = require('lodash');
    const [newUser, setNewUser] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [user, setUser] = useState([]);
    const [allCats, setAllCats] = useState([]);
    const [searchInput, setSearchInput] = useState("");

    useEffect(() =>{
        const fetchData = async () => {
            var allcats = await getAllCategories();
            setAllCats(allcats)
        }
        fetchData().finally(() => setDataLoaded(true));
    }, [])

    useEffect(() => {
        if(store.user.information){
            setUser(store.user);
            setNewUser(store.user);
            setDataLoaded(true)
        }
    }, [store.user])

    if (!dataLoaded) {
        return <div><Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
        </Spinner></div>;
    }

    const updatePersonInformation = (cat, subcatid, checked) => {
        const catid = allCats[cat].id;
        const updateUser = (subcat) => {
            return {
                ...user,
                information: {
                    ...user.information,
                    [cat]: {
                        ...user.information[cat],
                        subcategories: subcat,
                    },
                },
            };
        }
        if(checked) {
            const addSubcat = async () => {
                const data = {personid: user.id, catid: catid, subcatid: subcatid, value: checked}
                await addSubcategory(store.token, data)
                    .then(res => {
                        // Todo: check for 201 error (already exists)
                        const updatedUser = updateUser([...user.information[cat].subcategories, res])
                        setUser(updatedUser)
                        dispatch({type: STORE_ACTIONS.SET, payload: {key: "user", value: updatedUser}})
                    })
            }
            addSubcat().finally();
        }else {
            let personInfId = -1
            user.information[cat].subcategories.forEach(sub => {
                if(sub.subcat_id  === subcatid){
                    personInfId = sub.id
                }
            })
            if(personInfId !== -1){
                const deleteSubcat = async () => {
                    await deleteSubcategory(store.token, personInfId)
                        .then(res => {
                            const updatedUser = updateUser(user.information[cat].subcategories.filter(sub => personInfId !== sub.id))
                            setUser(updatedUser)
                            dispatch({type: STORE_ACTIONS.SET, payload: {key: "user", value: updatedUser}})
                        })
                }
                deleteSubcat().finally();
            }
        }
    }

    const createOwnSubcat = (cat_id, cat) => {
        const performCreation = async () => {
            const data = {name: searchInput, person_id: store.user.id, approved: 0, cat_id: cat_id, description: ""}
            await createSubcategory(data)
                .then(res => {
                    updatePersonInformation(cat, res.id, true)
                })
        }
        performCreation().finally();
    }

    const handleSearchInput = (e) => {
        const input = e.target.value;
        setSearchInput(input)
    }

    return (
        <div className="PersonSettings mt-20 container mx-auto ">
            <div className="card w-full bg-base-300 shadow-xl">
                <div className="card-body">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <ProfileInCol editing={true} user={user} img={store.images.profile?.filter(img => img['desc_sub_type'] === "profile_picture")} />
                        <FileInputGroup classes={"lg:mt-0 mt-8 min-h-16"} userId={user.id} img={store.images.profile?.filter(img => img['desc_sub_type'] === "visionboard")}>
                            <h2 className="text-lg mt-6 mb-6 lg:text-xl">Lade hier dein personalisiertes Visionboard hoch!</h2>
                        </FileInputGroup>
                        <div className="generalInformation lg:col-span-2 grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 mt-8 gap-3" >
                            {Object.keys(allCats).map(key => (
                                <CardGroup key={key} title={allCats[key].display_name} background={"base-100"}>
                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                        {store.user.information[key].subcategories.map((subcat, index) => (
                                            <div className="badge badge-primary" key={index}>{subcat.subcategory.name}</div>
                                        ))}
                                        <div className="col-span-2" onClick={() => setSearchInput("")}>
                                            <DropdownGroup title={"+"} labelClasses={"btn-sm btn-primary"}>
                                                <li>
                                                    <input type="text" placeholder="Suche..." value={searchInput}
                                                           onChange={handleSearchInput}
                                                           className="input input-bordered focus:input-primary w-full max-w-xs" />
                                                </li>
                                                <PersonInformationHandleDropdown allCats={allCats[key].subcategories}
                                                                                 userCats={user.information[key].subcategories}
                                                                                 searchInput={searchInput}
                                                                                 createSubcat={() => createOwnSubcat(allCats[key].id, key)}
                                                                                 updatePersonInformation={(subcat, val) => updatePersonInformation(key, subcat, val)}/>
                                                {allCats[key].subcategories.length === 0 && <li className="p-2">Keine Einträge</li>}
                                            </DropdownGroup>
                                        </div>
                                    </div>
                                </CardGroup>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PersonSettings;
